import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { Box, Typography, CircularProgress, Avatar } from "@mui/material";
import { colors } from "../lib/theme";
import { db } from "../../shared/firebase";
import { useAuth } from "../../shared/useAuth";
import ContainerBox from "../../shared/ContainerBox";
import AlbumList from "../../shared/AlbumList";

const reactRootDataset = document.getElementById("react-root").dataset;

const User = () => {
  const { user: currentUser, loading: userLoading } = useAuth();

  const userId = reactRootDataset.userId;

  const [user, setUser] = useState(null);
  const [albums, setAlbums] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sharedAlbums, setSharedAlbums] = useState([]);

  useEffect(() => {
    const fetchUserAndAlbums = async () => {
      try {
        // Fetch user data
        const userDoc = await getDoc(doc(db, "users", userId));
        if (userDoc.exists()) {
          setUser(userDoc.data());
        } else {
          setError("User not found");
        }

        // Fetch albums with privacyLevel 2
        const albumsRef = collection(db, "albums");
        const q = query(
          albumsRef,
          where("userId", "==", userId),
          where("privacyLevel", "==", 2)
        );
        const albumsSnapshot = await getDocs(q);
        const albumsList = albumsSnapshot.docs.map((doc) => {
          const albumData = { id: doc.id, ...doc.data() };
          console.log('Album data:', albumData);
          return albumData;
        });
        setAlbums(albumsList);

        // Fetch albums shared with the current user
        if (currentUser) {
          const sharedAlbumsRef = collection(db, "albums");
          const sharedQuery = query(
            sharedAlbumsRef,
            where("sharedWith", "array-contains", currentUser.uid),
            where("userId", "==", userId)
          );
          const sharedAlbumsSnapshot = await getDocs(sharedQuery);
          const sharedAlbumsList = sharedAlbumsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSharedAlbums(sharedAlbumsList);
        }

        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("An error occurred while fetching data");
        setLoading(false);
      }
    };

    fetchUserAndAlbums();
  }, [userId, currentUser]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress sx={{ color: colors.navy }} />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <>
      <ContainerBox>
        <Helmet>
          <title>
            {user ? `${user.firstName} ${user.lastName} - ` : ""} - Golden Record
          </title>
        </Helmet>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          {user && user.profilePhotoURL && (
            <Avatar
              src={user.profilePhotoURL}
              alt={`${user.firstName} ${user.lastName}`}
              sx={{ width: 60, height: 60, mr: 2 }}
            />
          )}
          <Typography variant="h3">
            {user ? `${user.firstName} ${user.lastName}` : "User"}
          </Typography>
        </Box>
        <AlbumList albums={albums} title="Public Albums" handleMenuOpen={() => {}} currentUser={user} />

        {currentUser && currentUser.uid !== userId && (
          <>
            <AlbumList albums={sharedAlbums} title="Shared with you" handleMenuOpen={() => {}} currentUser={user} />
          </>
        )}
      </ContainerBox>
    </>
  );
};

export default User;
